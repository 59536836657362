@import './variables';

// import font Domaine-Display from './fonts/Domaine-Display';
@font-face {
    font-family: 'Domaine-Display';
    src: url('/next/fonts/domaine-display/DomaineDispNar-Medium.woff');
    font-weight: normal;
    font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
    
    a {
      color: inherit;
      text-decoration: none;
    }
}

* {
  box-sizing: border-box;
  line-height: 1.5;
}

ul {
    list-style-type: none;
    padding: 0;
}

h1, h2, h3{
  margin: 0;
  font-family: Domaine-Display!important;
  font-weight: 300!important;

  &.journeys-h1-top-header{
    font-weight: 500!important;
  }

  &.journeys-h2-top-header{
    font-family: "Poppins"!important;
  }

  &.about-top-h2-text{
    font-family: "Poppins"!important;
    font-size: 24px!important;
    font-weight: 700!important;
  }
}

p{
  margin: 0;
}

.divider{
  border-bottom: $mainBorder;
}

.desktop{
  @include mobile{
    display: none;
  }
}

.mobile{
  display: none;
  @include mobile{
    display: block;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Custom scroll bar */
/* width */
::-webkit-scrollbar {
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color(rose);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: color(roseDark);
}

[contenteditable] {
  outline: 0px solid transparent;
}

.xo-link{
  color: color(blue);
  cursor: pointer;
}

.rose{
  color: color(rose);
}

.xo-link:hover{
  color: color(blueDark);
}

// Non-SASS CSS variables
:root {
  --swiper-theme-color: #ff4c00!important;

  --mainSectionPadding: 64px 10%;
  --secondarySectionPadding: 64px 2.5vw;
  --titleBottomMargin: 70px;
  
  @include mobile{
    --mainSectionPadding: 15% 4%!important;
    --secondarySectionPadding: 64px 3.5vw;
    --titleBottomMargin: 50px;
  }

  @include smallDesktop{
    --mainSectionPadding: 15% 8%;
    --titleBottomMargin: 50px;
  }

  @include bigDesktop{
    --mainSectionPadding: 64px 15%;
  }

}

// Overriding third party components
.mobileTrailers .swiper-button-next, .mobileTrailers .swiper-button-prev, .videosStripe .swiper-button-next, .videosStripe .swiper-button-prev{
  color: white;
}
.mobileTrailers .swiper-button-prev{
  left: 0;
}
.mobileTrailers .swiper-button-next{
  right: 0;
}

.swiper{
  overflow: initial!important;
}

.swiper-slide {
  width: 100vw !important;
}

.mobileTrailers .swiper-button-next:after, .mobileTrailers .swiper-button-prev:after{
  font-size: 2em;
  font-weight: 600;
}

.videosStripe .swiper-slide{
  width: auto;
  flex-shrink: 1;
}

.videosStripe__video-hovering .swiper-slide{
  z-index: 3!important;
}

.videosStripe .swiper-button-next, .videosStripe .swiper-button-prev{
  margin-top: 0;
  top: 40%;
  transform: translateY(-50%) scale(.85);
  font-weight: bold;

  @include mobile{
    display: none;
  }
}

.swiper-button-prev, .swiper-button-next{
  transition: .2s!important;
}

.swiper-button-disabled{
  opacity: 0!important;
}

.swiper-pagination.swiper-pagination-bullets{
  bottom: 0!important;
  // margin-left: -27%;  
}

.homepage .swiper-pagination.swiper-pagination-bullets{
  bottom: 10px!important;
}

.xoMedia .swiper-pagination-bullet{
  background-color: white;
  opacity: 1;
}

.xoMedia .swiper-pagination-bullet-active{
  background-color: #5793E9;
}

.heroSwiper .swiper-slide{
  height: auto;
}

.watchpage [data-class=HeroTrailer]{
  background-size: cover!important;
}

.watchpage .heroTrailerSlider [data-class=HeroTrailer]{
  background-size: contain!important;
}

// Overriding wordpress's classes for blogposts
.blogPost{

  .big-button{
    background-color: color(rose);
    color: white!important;
    text-decoration: none!important;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    padding: 12px 16px;
    font-family: Poppins;
    cursor: pointer;
    text-align: center;
  }

  .wp-video{
    max-width: 100%;
  }

  .et_audio_container{
    margin-bottom: 30px;
  }

}

// Hiding injected img snippets that might be placed by Tag Manager
.injected_snippet{
  height: 0;
  z-index: -2;
  position: absolute;
}

.glowUp{
  overflow: hidden;
  position: relative;
}

.glowUp:after{
  animation: glowUp 5s ease-in-out;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -200%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);    
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
  );
}

@keyframes glowUp{
  10% {
    opacity: .5;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  25%{
    opacity: .2;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.afterglow-bullet-icon{
  height: 20px;
  width: auto;
  margin-top: 50%;
}

.subscription_success_outer_container_desktop{
  background-color: #000000;
  min-height: calc(100vh - 313px);
}

.subscription_success_outer_container_mobile{
  background-color: #000000;
  min-height: calc(100vh - 490px);
}