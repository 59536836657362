@import '../../../styles/variables';

.button{
    border: none;
    outline: none;
    padding: 10px 25px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: color(error);
    color: white;
    font-family: Poppins;
    transition: .2s;
    font-weight: 500;
    border-radius: 18px;
}

.button.button-transparent{
    border: $mainBorder;
    background-color: transparent;
    transition: .2s;
}

.button-active.button-transparent:hover{
    background-color: white;
    color: black;
}

.button.button-error.button-inActive{
    background-color: color(error)!important;
}

.button.button-black{
    background-color: black;
}

.button.button-black:hover{
    background-color: color(charcoal);
}

.button.button-blue{
    background-color: color(blueDark);
}

.button.button-glassy{
    background: radial-gradient(200.19% 157.53% at 47.33% 49.32%, rgba(255, 255, 255, 0) 4.17%, rgba(255, 254, 254, 0.0110429) 4.18%, rgba(255, 255, 255, 0.6) 89.06%);
}

.button-active:hover{
    background-color: color(error);
    cursor: pointer;
}

.button-inActive{
    opacity: .4;
}
.button-inActive:hover{
    cursor: initial;
}

.button.button-clicked{
    opacity: 75%;
}