@import '../../../styles/variables';

.xoLoader{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .xoLoader__iconContainer{
        position: absolute;

        svg{

            path{
                animation: fade 1s linear infinite;
                animation-fill-mode: both;
            }

        }

    }
}

@keyframes fade { 
    0% {
      opacity: .6;
    }

    20%{
        opacity: 1;
    }
  
    100% {
        opacity: 1;
    }
}

