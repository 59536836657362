@import '../../../../styles/variables';

.nav{

    background-color: rgba(0,0,0,0.2);
    padding: 10px 5% 10px 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 4;
    font-size: .8em;
    backdrop-filter: $mainBlur;
    -webkit-backdrop-filter: $mainBlur;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
    font-weight: 600;

    .nav__logo{
        cursor: pointer;
        line-height: 0;

        img {
            width: 110px;
        }
    }

    .nav__tabs{
        display: flex;
        text-transform: uppercase;
        align-items: center;
        position: relative;
        font-size: 14px;

        .nav__tab{
            margin-right: 70px;
            color: #FFFFF3;
            cursor: pointer;
            transition: .2s;
            white-space: nowrap;

            @include smallDesktop{
                margin-right: 20px;
            }
        }

        .nav__tab-active{
            color: color(blue);
            
            button{
                background-color: white;
                color: color(charcoalDark);
            }
        }

        .nav__tab:hover{
            color: color(blue)
        }

        .nav__loginBtnContainer{
            position: absolute;
            right: 0;
        }

        .nav__loginPlaceholder{
            padding: 0 25px;
            opacity: 0;
        }

        .nav__dropDownContainer{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 3px;

            .nav__articles_icon_container{
                width: 1px;
                overflow: visible;
                margin: auto;
            }

            .nav__articles_link_arrow_icon{
                color: #FFFFF3;
                margin-top: 2px;
                margin-left: 2px;        
            }

            .nav__parentTab{
                z-index: 2;
                display: flex;
            }

            .nav__articles_offset_outline_box{
                position: absolute;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                border: 1px solid #FFFFF3;
                border-radius: 4px;
                height: 115px;
                width: 155px;
                opacity: 75%;
                margin-top: 10px;
                background-color: #000000BF;
            }
            
            .nav__dropDownTab{
                position: absolute;
                padding-top: 20px;
                
                .nav__tab{
                    padding: 5px 10px;
                    text-transform: capitalize;
                    font-family: 'Poppins';
                    font-weight: 400;
                    font-size: .75rem;
                    color: #FFFFF3;
                    margin-top: -40px;
                }

                .nav__tab:hover{
                    color: #9C7EDF;
                }
            }

            .account_dropdown_container_admin{
                position: absolute;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                border: 1px solid #FFFFF3;
                border-radius: 4px;
                height: 60px;
                width: 100px;
                background-color: #000000BF;
                opacity: 75%;
                margin-top: 105px;
            }

            .account_dropdown_container_no_admin{
                position: absolute;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                border: 1px solid #FFFFF3;
                border-radius: 4px;
                height: 40px;
                width: 90px;
                background-color: #000000BF;
                opacity: 75%;
                margin-top: 85px;
            }
        }
    }
    
    .nav__mainBtns{
        display: flex;
       
        button{
            font-weight: 600;
        }
        
        >div:first-child{
            margin-right: 20px;
        }
    }

    .nav__dash{
        height: 2px;
        background-color: color(rose);
        position: absolute;
        bottom: 0;
        left: 0;
    }

}

.nav-noTabs{
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    
    .nav__tabs{
        display: none;
    }
}