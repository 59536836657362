$colors : (
    beige: #fff7e1,
    oceanDark: #273d40,
    rose: #ff02c8,
    roseDark: #d600a8,
    charcoal: #181714,
    charcoalDark: #000000,
    error: #ff4c00,
    errorDark: #c73c00,
    success: #75C89C,
    blue: #9c7edf,
    blueDark: #7d59c9,
    glow: #F6BC41,
);

@function color ($color-name) {
    @return map-get($colors, $color-name)
}

$mainBorderRadius: 16px;
$mainBorder: 1px solid white;
$mainBoxShadow: 0px 0px 8px 2px rgba(0, 0, 0, .2);
$mainSectionBoxShadow: 2px 2px 20px 5px rgb(0 0 0 / 40%) inset;
$mainBlur: blur(3px);
$mainTransition: .6s ease-out;
$viewPaddingTop: 120px;

$contentNavigationWidth: 20vw;
$authInputs: 350px;

// Glassmorphic variables
$gmBackground: radial-gradient(200.19% 200.53% at 47.33% 49.32%, rgba(255, 255, 255, 0) 4.17%, rgb(255 255 255 / 50%) 89.06%);
$gmFilter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));

// Devices
$mobile: 800px;
$smallDesktop: 1050px;
$desktop: 1300px;
$bigDesktop: 1600px;

@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin smallDesktop {
    @media (max-width: #{$smallDesktop}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin bigDesktop {
    @media (min-width: #{$bigDesktop}) {
        @content;
    }
}
