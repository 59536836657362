@import '../../../styles/variables';

.card{
    >div{
        display: flex;
        align-items: center;
    }
}

.card-hasBorder{
    border: $mainBorder;
    padding: 2px 5px;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: .2s;

    >div{
        position: relative;
    }
}

.card-inactive{
    opacity: .2;
}