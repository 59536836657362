@import '../../../styles/variables';

.nav{

    .nav__mobile{
        display: none;
        
        @include mobile{
            display: flex;
        }
    }

    .nav__mobile-dark{
        nav{
            >div{
                background-color: #141414;
            }
        }
    }

    .nav__desktop{
        @include mobile{
            display: none;
        }
    }

    .nav__desktop-dark{
        nav{
            background-color: #141414;

            @include mobile{
                background-color: transparent;
            }
        }
    }
}