@import '../../../styles/variables';

.asterisk{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    position: absolute;
    width: 180px;
    color: white;
    transition: .2s;
    text-shadow: 1px 1px 1px rgba(0,0,0,.7);


    .asterisk__texts{
        z-index: 1;
        position: absolute;
        transform: rotateZ(15deg);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        letter-spacing: 1px;
            
        .asterisk__mainText{
            font-weight: 600;
            margin-bottom: 5px;
        }

        .asterisk__subText{
            font-size: .55em;
        }
        
    }


    .asterisk__stars{
        display: flex;
        justify-content: center;
        align-items: center;

        .asterisk__star{
            position: absolute;
        }
        
        .asterisk__star:last-child{
            transform: rotateZ(-40deg);
        }

        path{
            transition: .2s;
        }

    }
}