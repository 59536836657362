@import '../../../styles/variables';

.footer{
    background-color: color(charcoalDark);
    width: 100%;
    padding: var(--mainSectionPadding);
    color: white;

    @include mobile{
        font-size: .9em;
    }

    .footer__social{
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
        >div{
            margin-right: 5px;
        }
        >div:last-child{
            margin-right: 0;
        }
    }

    .footer__legal{
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mobile{
            align-items: flex-start;
        }

        >div{
            margin-bottom: 14px;
        }

        >div:last-child span{
            color: color(rose);
        }

        >div:first-child{
            margin: 23px 0;
        }

        .footer__legalTabs{
            display: flex;
            width: 100%;
            justify-content: center;

            @include mobile{
                flex-direction: column;
            }
            
            >div{
                padding: 0 30px;
                border-right: $mainBorder;
                transition: .1s;

                @include mobile{
                    border-right: none;
                    padding: 0;
                    margin-bottom: 5px;
                }
            }

            >div:hover{
                color: color(rose);
                cursor: pointer;
            }

            >div:last-child{
                border-right: none;
            }       

        }
        
        .footer__divider{
            display: none;

            @include mobile{
                display: block;
                border: $mainBorder;
                width: 40px;
                opacity: .7;
            }
        }
    }

    .footer__payment{
        display: flex;
        justify-content: center;

        @include mobile{
            justify-content: flex-start;
        }

        >div{
            display: flex;
            align-items: center;
            >div{
                margin-right: 15px;
            }
        }
    }

}