@import '../../../styles/variables';

.promptContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    transition: .2s;
    z-index: 3;
}

.promptContainer-hidden{
    display: none;
}

.promptContainer-hiding{
    background-color: rgba(0,0,0, 0);
}

.promptContainer__modalWrapper{
    transition: .2s;

    @include mobile{
        max-width: 90vw;
    }
}

.promptContainer__modalWrapper-hidden{
    transform: scale(.7);
    opacity: 0;
}