@import '../../../styles/variables';

.fullScreenLoader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: color(charcoal);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}