@import '../../../styles/variables';

.plus18{
    padding: 48px 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: color(charcoalDark);
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
    color: white;
    text-align: center;

    @include mobile{
        padding: 36px 24px;
    }

    >div{
        margin-bottom: 32px;
    }

    >div:last-child{
        margin-bottom: 0;
    }

    .question{
        font-size: 22px;
        font-weight: 500;
    }

    .divider{
        width: 50px;
        border: 1px solid white;
    }

    .consent{
        display: flex;
        font-size: 19px;

        >div:first-child{
            margin-right: 15px;
        }

        input {
            height: 30px;
            width: 30px;
            padding-top: 25px;
            position: relative;
            top: 5px;
        }
    }
}

.inputRadio{
    background-color: color(charcoal);
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid color(blueDark);
    display: flex;
    justify-content: center;
    align-items: center;

    >div{
        width: 12px;
        height: 12px;
        border-radius: 4px;
        background-color: color(blueDark);
    }
}

.plus-18-new-logo-container{
   max-width: 150px;
}

.plus-18-new-logo-image{
    max-width: 100%;
}

.consentLabel {
    margin-top: 5px;
    margin-left: 8px;
}
