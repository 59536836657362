@import '../../../../styles/variables';

.nav{
    position: fixed ;
    width: 100%;
    z-index: 3;
    padding-top: 0px;
    padding-bottom: 0px;

    .nav__logo{
        height: 24px;
    }

    .nav__filters{
        color: white;
        position: absolute;
        right: 4%;
        margin-top: 9px;
    }

    >div{
        transition: .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: var(--mainSectionPadding);
        padding-top: 20px;
        padding-bottom: 20px;

        .nav__hamburger{
            position: absolute;
            left: 4%;
        }

        .nav__backBtn{
            line-height: 0;
            position: absolute;
            left: 0;
            padding: 4%;
        }   
    }

    .nav__hamburgerMenu{
        height: 100vh;
        padding: 30px 40px;
        position: fixed;
        background-color: color(charcoal);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: .25s ease-out;
        transform: translateX(-100%);
        max-width: 80vw;
        box-shadow: $mainBoxShadow;

        .nav__hamburgerMenuLogo{
            margin-bottom: 10px;
        }

        ul{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 1.375rem;

            .nav__tab-active{
                color: color(rose);

                button{
                    background-color: white;
                    color: color(charcoalDark);
                }
            }

            button{
                font-size: 1.2rem;
                font-weight: 600;
                width: 220px;
            }

            li{
                color: white;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 30px;
                text-align: center;

                &.mobile-menu-extra-bold-link{
                    font-weight: 800;
                    font-family: "Poppins", sans-serif;
                  } 
                
                &.mobile_nav_articles_indented_links{
                    margin-left: 23px;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: none;
                }

                &.mobile_nav_account_links{
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: none;
                }
            }
        }
    }

    .nav__hamburgerMenu-open{
        transform: translateX(0);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .nav__dynamicRightMenu{
        position: fixed;
        height: 100vh;
        padding: 0;
        background-color: color(charcoal);
        top: 0;
        right: 0;
        transition: .25s ease-out;
        transform: translateX(100%);
        width: 80vw;
        box-shadow: $mainBoxShadow;
        color: white;
    }

    .nav__dynamicRightMenu-open{
        transform: translateX(0);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.nav-scrolled > div{
    background-color: rgba(0,0,0,.2);
    backdrop-filter: $mainBlur;
    -webkit-backdrop-filter: $mainBlur;
    box-shadow: $mainBoxShadow;
}

.nav-open{
    height: 100vh;
    >div:first-child{
        transform: translateY(-100%);
    }
}

.nav-noTabs{
    .nav__hamburger{
        display: none;
    }
}

.mobile_nav_new_logo{
    width: 110px;
}

.nav__articles_link_arrow_icon_mobile{
    display: inline-flex;
    margin-left: 6px;
}